import _colors from "./lib/colors";
var exports = {};
//
// Remark: Requiring this file will use the "safe" colors API,
// which will not touch String.prototype.
//
//   var colors = require('colors/safe');
//   colors.red("foo")
//
//
var colors = _colors;
exports = colors;
export default exports;